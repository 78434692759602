import React from 'react';
import {Link} from "react-router-dom";
import './Gallery.css';
import ImageGrid from "./ImageGrid";

class Gallery extends React.Component {

    constructor(props) {
        super(props);
        let destination;
        if(!this.props.match){
            destination = "";
        } else {
            destination = this.props.match.params.destination;
        }
        this.state= {
            destination: destination
        };
        this.loadImages = this.loadImages.bind(this)
    }

    loadImages(destination) {
        switch (destination){
            case "New Zealand":
                return this.setState({title: "New Zealand - 2019", items: require('./newZealandImages.json'), backgroundColor: "aliceblue", titleColor: "forestgreen"});
            case "Myanmar":
                return this.setState({title: "Myanmar - 2018", items: require('./myanmarImages.json'), backgroundColor: "honeydew", titleColor: "coral"});
            case "China":
                return this.setState({title: "China - 2018", items: require('./chinaImages.json'), backgroundColor: "whitesmoke", titleColor: "tomato"});
            case "Japan":
                return this.setState({title: "Japan - 2018", items: require('./japanImages.json'), backgroundColor: "seashell", titleColor: "lightsalmon"});
            case "Ireland":
                return this.setState({title: "Ireland - 2020", items: require('./irelandImages.json'), backgroundColor: "azure", titleColor: "dodgerblue"});
            case "Singapore":
                return this.setState({title: "Singapore - 2018", items: require('./singaporeImages.json'), backgroundColor: "lightgrey", titleColor: "goldenrod"});
            case "Iceland":
                return this.setState({title: "Iceland - 2021", items: require('./icelandImages.json'), backgroundColor: "azure", titleColor: "darkorange"});
            case "Taiwan":
                return this.setState({title: "Taiwan - 2018", items: require('./taiwanImages.json'), backgroundColor: "whitesmoke", titleColor: "gold"});
            case "Cambodia":
                return this.setState({title: "Cambodia - 2018", items: require('./cambodiaImages.json'), backgroundColor: "mintcream", titleColor: "dimgray"});
            default:
                return this.setState({title: "New Zealand - 2019", items: require('./newZealandImages.json'), backgroundColor: "aliceblue", titleColor: "forestgreen"});
        }
    }

    componentDidMount() {
        this.loadImages(this.state.destination);
    }

    render() {
        return (
            <div id='GalleryWrapper' style={{backgroundColor: this.state.backgroundColor}}>
                <div id='GalleryHeader'>
                    <Link id='GalleryReturnLink' to='/'>
                        <b>↢</b> Discover More!
                    </Link>
                    <div id='GalleryTitle' style={{color: this.state.titleColor}}>
                        {this.state.title}
                    </div>
                </div>
                <div id='ImageGrid'>
                    <ImageGrid items={this.state.items} />
                </div>
            </div>
        );
    }
}

export default Gallery;

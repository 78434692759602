import React from 'react';
import travelsImageTopPart from '../../travels_top.webp';
import travelsImageBottomPart from '../../travels_bottom.webp';
import travelsImageBottomLeftPart from '../../travels_bottom_left.webp';
import './Travels.css';
import TravelDestinations from "../travelDestinations/TravelDestinations.js";

class Travels extends React.Component {

    state = {
        width: 0,
        height: 0,
        destinationsActivated: false,
    };
    letterData = [];

    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize);
    }

    componentDidMount() {
        window.addEventListener('resize', this.onResize);
        this.letterData = [
            {
                element: document.getElementById('T'),
                xOffset: -490,
                yOffset: -455
            },
            {
                element: document.getElementById('R'),
                xOffset: -290,
                yOffset: -535
            },
            {
                element: document.getElementById('A'),
                xOffset: -90,
                yOffset: -600
            },
            {
                element: document.getElementById('V'),
                xOffset: 110,
                yOffset: -555
            },
            {
                element: document.getElementById('E'),
                xOffset: 310,
                yOffset: -610
            },
            {
                element: document.getElementById('L'),
                xOffset: 510,
                yOffset: -560
            },
            {
                element: document.getElementById('S'),
                xOffset: 710,
                yOffset: -510
            }
        ];
        this.onResize();
    }

    onResize = () => {
        this.letterData.forEach(function(letter) {
            let element = letter.element;
            element.classList.remove('TravelsLetterAnimation');
            let newX;
            let newY;
            if(window.innerWidth/window.innerHeight > 1.5){
                newX = (letter.xOffset/5184)*window.innerWidth;
                newY = (letter.yOffset/5184)*window.innerWidth;
                letter.element.style.fontSize = '5vw';
            } else {
                newX = (letter.xOffset/3456)*window.innerHeight;
                newY = (letter.yOffset/3456)*window.innerHeight;
                letter.element.style.fontSize = '7.5vh';
            }
            letter.element.style.left = ((window.innerWidth/2)+newX) + 'px';
            letter.element.style.top = ((window.innerHeight/2)+newY) + 'px';
            setTimeout(() => {
                letter.element.classList.add('TravelsLetterAnimation');
            }, 400);
        });
        this.setState({
            width: window.innerWidth,
            height: window.innerHeight
        });
    };

    onLetterHover = () => {
        this.letterData.forEach(function(letter) {
           letter.element.classList.add('TravelsLetterOnHover');
           letter.element.classList.remove('TravelsLetterOffHover');
            if(window.innerWidth/window.innerHeight > 1.5){
                letter.element.style.fontSize = '5.5vw';
            } else {
                letter.element.style.fontSize = '9.25vh';
            }
        });
        if(!this.state.destinationsActivated){
            this.setState({
                destinationsActivated: true
            })
        }
    }

    onLetterLeave = () => {
        this.letterData.forEach(function(letter) {
            letter.element.classList.add('TravelsLetterOffHover');
            letter.element.classList.remove('TravelsLetterOnHover');
            if(window.innerWidth/window.innerHeight > 1.5){
                letter.element.style.fontSize = '5vw';
            } else {
                letter.element.style.fontSize = '7.5vh';
            }
        });
    }

    onTravelsTextClick = () => {
        if(!this.state.destinationsFullyVisible){
            this.setState({
                destinationsActivated: true,
                destinationsFullyVisible: true
            })
        } else {
            this.setState({
                destinationsActivated: true,
                destinationsFullyVisible: false
            })
        }
    }

    render() {
        return (
            <div className='Travels'>
                <img alt='' src={travelsImageTopPart} id='imagePartTop' className='backgroundImage' />
                <div id='travelsText'>
                    <div onClick={this.onTravelsTextClick}>
                        <div id='T' className='TravelsLetter TravelsLetterOffHover' onMouseOver={this.onLetterHover} onMouseOut={this.onLetterLeave} >T</div>
                        <div id='R' className='TravelsLetter TravelsLetterOffHover' onMouseOver={this.onLetterHover} onMouseOut={this.onLetterLeave} >R</div>
                        <div id='A' className='TravelsLetter TravelsLetterOffHover' onMouseOver={this.onLetterHover} onMouseOut={this.onLetterLeave} >A</div>
                        <div id='V' className='TravelsLetter TravelsLetterOffHover' onMouseOver={this.onLetterHover} onMouseOut={this.onLetterLeave} >V</div>
                        <div id='E' className='TravelsLetter TravelsLetterOffHover' onMouseOver={this.onLetterHover} onMouseOut={this.onLetterLeave} >E</div>
                        <div id='L' className='TravelsLetter TravelsLetterOffHover' onMouseOver={this.onLetterHover} onMouseOut={this.onLetterLeave} >L</div>
                        <div id='S' className='TravelsLetter TravelsLetterOffHover' onMouseOver={this.onLetterHover} onMouseOut={this.onLetterLeave} >S</div>
                    </div>
                </div>
                <div id='destinations'>
                    {this.state.destinationsActivated && <TravelDestinations activated={this.state.destinationsActivated} fullyVisible={this.state.destinationsFullyVisible}/>}
                </div>
                <img alt='' src={travelsImageBottomPart} id='imagePartBottom' className='backgroundImage' />
                <img alt='' src={travelsImageBottomLeftPart} id='imagePartBottomLeft' className='backgroundImage' />
            </div>
        );
    }
}

export default Travels;

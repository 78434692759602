import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import './index.css';
import Travels from './components/travels/Travels';
import Gallery from "./components/gallery/Gallery";

ReactDOM.render(
  <React.StrictMode>
      <Router>
          <div>
              <Switch>
                  <Route exact path="/" render={(props) => <Travels />} />
                  <Route exact path="/gallery" render={(props) => <Gallery />} />
                  <Route exact path="/gallery/:destination" render={(props) => <Gallery {...props} />} />
              </Switch>
          </div>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

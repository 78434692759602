import React from 'react';
import './ImageGrid.css';
import ImageView from "./ImageView";

class ImageGrid extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            imageView: false,
            imageViewId: 0
        };
        this.toggleImage = this.toggleImage.bind(this)
        this.nextImage = this.nextImage.bind(this)
        this.previousImage = this.previousImage.bind(this)
    }

    toggleImage(event, imageId) {
        if (!this.state.imageView || event.target.classList.contains("ImageViewImageWrapper") || event.target.classList.contains("ImageViewBackground")) {
            this.setState({ imageView: !this.state.imageView, imageViewId: imageId});
        }
    };

    nextImage(event, currentImage) {
        let nextImageId = this.props.items[currentImage.id+1]===undefined ? 0 : currentImage.id+1;
        this.setState({imageViewId: nextImageId});
    };

    previousImage(event, currentImage) {
        let previousImageId = this.props.items[currentImage.id-1]===undefined ? this.props.items.length-1 : currentImage.id-1;
        this.setState({imageViewId: previousImageId});
    };

    render() {
        return (
            <div className="ImageGrid">
                {this.props.items &&
                    this.props.items.map((image) => (
                        <div className="ImageGridBox" key={image.id} onClick={(e) => this.toggleImage(e, image.id)}>
                            <img src={image.thumbnail} alt=""/>
                        </div>
                    ))}
                {this.state.imageView &&
                    <ImageView image={this.props.items[this.state.imageViewId]} toggleImage={this.toggleImage} nextImage={this.nextImage} previousImage={this.previousImage}></ImageView>
                }
            </div>
        );
    }
}

export default ImageGrid;
